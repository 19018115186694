exports.components = {
  "component---src-pages-cs-404-tsx": () => import("./../../../src/pages/cs/404.tsx" /* webpackChunkName: "component---src-pages-cs-404-tsx" */),
  "component---src-pages-cs-case-studies-conti-cs-tsx": () => import("./../../../src/pages/cs/case-studies/conti_cs.tsx" /* webpackChunkName: "component---src-pages-cs-case-studies-conti-cs-tsx" */),
  "component---src-pages-cs-case-studies-covid-model-cs-tsx": () => import("./../../../src/pages/cs/case-studies/covid_model_cs.tsx" /* webpackChunkName: "component---src-pages-cs-case-studies-covid-model-cs-tsx" */),
  "component---src-pages-cs-case-studies-ikem-cs-tsx": () => import("./../../../src/pages/cs/case-studies/ikem_cs.tsx" /* webpackChunkName: "component---src-pages-cs-case-studies-ikem-cs-tsx" */),
  "component---src-pages-cs-case-studies-lexxus-cs-tsx": () => import("./../../../src/pages/cs/case-studies/lexxus_cs.tsx" /* webpackChunkName: "component---src-pages-cs-case-studies-lexxus-cs-tsx" */),
  "component---src-pages-cs-case-studies-llm-cs-tsx": () => import("./../../../src/pages/cs/case-studies/llm_cs.tsx" /* webpackChunkName: "component---src-pages-cs-case-studies-llm-cs-tsx" */),
  "component---src-pages-cs-case-studies-mrparkit-cs-tsx": () => import("./../../../src/pages/cs/case-studies/mrparkit_cs.tsx" /* webpackChunkName: "component---src-pages-cs-case-studies-mrparkit-cs-tsx" */),
  "component---src-pages-cs-case-studies-onsemi-cs-tsx": () => import("./../../../src/pages/cs/case-studies/onsemi_cs.tsx" /* webpackChunkName: "component---src-pages-cs-case-studies-onsemi-cs-tsx" */),
  "component---src-pages-cs-case-studies-teijin-cs-tsx": () => import("./../../../src/pages/cs/case-studies/teijin_cs.tsx" /* webpackChunkName: "component---src-pages-cs-case-studies-teijin-cs-tsx" */),
  "component---src-pages-cs-index-tsx": () => import("./../../../src/pages/cs/index.tsx" /* webpackChunkName: "component---src-pages-cs-index-tsx" */),
  "component---src-pages-cs-kontakt-tsx": () => import("./../../../src/pages/cs/kontakt.tsx" /* webpackChunkName: "component---src-pages-cs-kontakt-tsx" */),
  "component---src-pages-cs-poptavka-tsx": () => import("./../../../src/pages/cs/poptavka.tsx" /* webpackChunkName: "component---src-pages-cs-poptavka-tsx" */),
  "component---src-pages-cs-sluzby-business-intelligence-tsx": () => import("./../../../src/pages/cs/sluzby/business-intelligence.tsx" /* webpackChunkName: "component---src-pages-cs-sluzby-business-intelligence-tsx" */),
  "component---src-pages-cs-sluzby-data-science-tsx": () => import("./../../../src/pages/cs/sluzby/data-science.tsx" /* webpackChunkName: "component---src-pages-cs-sluzby-data-science-tsx" */),
  "component---src-pages-cs-sluzby-pokrocila-data-analytika-tsx": () => import("./../../../src/pages/cs/sluzby/pokrocila-data-analytika.tsx" /* webpackChunkName: "component---src-pages-cs-sluzby-pokrocila-data-analytika-tsx" */),
  "component---src-pages-cs-spoluprace-tsx": () => import("./../../../src/pages/cs/spoluprace.tsx" /* webpackChunkName: "component---src-pages-cs-spoluprace-tsx" */),
  "component---src-pages-en-404-tsx": () => import("./../../../src/pages/en/404.tsx" /* webpackChunkName: "component---src-pages-en-404-tsx" */),
  "component---src-pages-en-case-studies-abb-en-tsx": () => import("./../../../src/pages/en/case-studies/abb_en.tsx" /* webpackChunkName: "component---src-pages-en-case-studies-abb-en-tsx" */),
  "component---src-pages-en-case-studies-conti-en-tsx": () => import("./../../../src/pages/en/case-studies/conti_en.tsx" /* webpackChunkName: "component---src-pages-en-case-studies-conti-en-tsx" */),
  "component---src-pages-en-case-studies-covid-model-en-tsx": () => import("./../../../src/pages/en/case-studies/covid_model_en.tsx" /* webpackChunkName: "component---src-pages-en-case-studies-covid-model-en-tsx" */),
  "component---src-pages-en-case-studies-ikem-en-tsx": () => import("./../../../src/pages/en/case-studies/ikem_en.tsx" /* webpackChunkName: "component---src-pages-en-case-studies-ikem-en-tsx" */),
  "component---src-pages-en-case-studies-lexxus-en-tsx": () => import("./../../../src/pages/en/case-studies/lexxus_en.tsx" /* webpackChunkName: "component---src-pages-en-case-studies-lexxus-en-tsx" */),
  "component---src-pages-en-case-studies-llm-en-tsx": () => import("./../../../src/pages/en/case-studies/llm_en.tsx" /* webpackChunkName: "component---src-pages-en-case-studies-llm-en-tsx" */),
  "component---src-pages-en-case-studies-mrparkit-en-tsx": () => import("./../../../src/pages/en/case-studies/mrparkit_en.tsx" /* webpackChunkName: "component---src-pages-en-case-studies-mrparkit-en-tsx" */),
  "component---src-pages-en-case-studies-onsemi-en-tsx": () => import("./../../../src/pages/en/case-studies/onsemi_en.tsx" /* webpackChunkName: "component---src-pages-en-case-studies-onsemi-en-tsx" */),
  "component---src-pages-en-case-studies-teijin-en-tsx": () => import("./../../../src/pages/en/case-studies/teijin_en.tsx" /* webpackChunkName: "component---src-pages-en-case-studies-teijin-en-tsx" */),
  "component---src-pages-en-collaboration-tsx": () => import("./../../../src/pages/en/collaboration.tsx" /* webpackChunkName: "component---src-pages-en-collaboration-tsx" */),
  "component---src-pages-en-contact-tsx": () => import("./../../../src/pages/en/contact.tsx" /* webpackChunkName: "component---src-pages-en-contact-tsx" */),
  "component---src-pages-en-demand-tsx": () => import("./../../../src/pages/en/demand.tsx" /* webpackChunkName: "component---src-pages-en-demand-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-services-advanced-data-analytics-tsx": () => import("./../../../src/pages/en/services/advanced-data-analytics.tsx" /* webpackChunkName: "component---src-pages-en-services-advanced-data-analytics-tsx" */),
  "component---src-pages-en-services-business-intelligence-tsx": () => import("./../../../src/pages/en/services/business-intelligence.tsx" /* webpackChunkName: "component---src-pages-en-services-business-intelligence-tsx" */),
  "component---src-pages-en-services-data-science-tsx": () => import("./../../../src/pages/en/services/data-science.tsx" /* webpackChunkName: "component---src-pages-en-services-data-science-tsx" */),
  "component---src-pages-examples-index-tsx": () => import("./../../../src/pages/examples/index.tsx" /* webpackChunkName: "component---src-pages-examples-index-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank_you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */)
}

